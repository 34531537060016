import React from "react";
import "./ContactUs.css";

const Contact = () => {
  return (
    <div className="contact-us" id="contact" smooth>
      <div className="wedo-cont-first">
        <h4 className="">
          Contact <span className="span-wedo">Us</span>
        </h4>

        <p>Feel free to Contact us</p>
      </div>

      <div className="form-wrapper">
        <iframe
          title="Zoho form"
          aria-label="Contact Us"
          frameborder="0"
          className="form-iframe"
          src="https://forms.zohopublic.eu/joshuaaristei1/form/ContactUs/formperma/faL9B96tz-Oh_jP-iILbmu416wIj19puZWH4IrL3b_8"
        ></iframe>
      </div>
    </div>
  );
};

export default Contact;
