import React from "react";
import "./Home.css";
import Header from "../components/Header/Header";
import Services from "../components/Services";
import Wedo from "../components/Wedo";
import Contact from "../components/ContactUs";
import Partners from "../components/Partners";
import Slider from "../components/Slider";
import Footer from "../components/Footer";
import { BrowserRouter } from "react-router-dom";
// import { HashLink as Link } from "react-router-hash-link";

const Home = () => {
  return (
    <BrowserRouter>
      {/* <Link to="#about" smooth>
        about
      </Link>
      <Link to="#contact" smooth>
        contact
      </Link> */}

      <div className="home">
        <Header />
        <Services />
        <Wedo />
        <Partners />
        <Slider />
        <Contact />
        <Footer />
      </div>
    </BrowserRouter>
  );
};

export default Home;
