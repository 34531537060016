import React from "react";
import "./Footer.css";
import fb from "../images/fbook.jpg";
import linkedin from "../images/linkedin.jpg";
import X from "../images/twitter.jpg";

const Footer = () => {
  return (
    <div className="footer">
      <div className="sb__footer section__padding">
        <div className="sb__footer-links">
          <div className="sb__footer-links_div">
            <img src="images/logo-B.png" className="footer-logo" alt="" />
            {/* <h4>For Business</h4>
                        <a href='/employer'>
                        <p>Employer</p>
                        </a>
                        <a href='/healthplan'>
                        <p>Health Plan</p>
                        </a>
                        <a href='/individual'>
                        <p>Individual</p>
                        </a> */}
          </div>
          <div className="sb__footer-links_div">
            <h4>Resources</h4>
            {/* <a href='/resource'>
                        <p>Resource center</p>
                        </a> */}
            <a href="/">
              <p>Testimonials</p>
            </a>
            {/* <a href='/resource'>
                        <p>STV</p>
                        </a> */}
          </div>
          <div className="sb__footer-links_div">
            <h4>Partners</h4>
            <a href="/">
              <p>AOC Schengen</p>
            </a>
            <a href="/">
              <p>Jobberman</p>
            </a>
            <a href="/">
              <p>DOT</p>
            </a>
          </div>
          <div className="sb__footer-links_div">
            <h4>Company</h4>
            <a href="/">
              <p>About</p>
            </a>
            {/* <a href='/press'>
                        <p>Press</p>
                        </a> */}
            {/* <a href='/career'>
                        <p>Career</p>
                        </a> */}
            <a href="/">
              <p>Contact</p>
            </a>
          </div>
          <div className="sb__footer-links_div">
            <h4>Social Media</h4>
            <div className="socialmedia">
              <a
                href="https://www.facebook.com/profile.php?id=61567722536057"
                target="_blank"
                rel="noreferrer"
              >
                <p>
                  <img src={fb} alt="" />
                </p>
              </a>
              <a
                href="https://www.linkedin.com/company/aristeia-consult/about"
                target="_blank"
                rel="noreferrer"
              >
                <p>
                  <img src={linkedin} alt="" />
                </p>
              </a>
              <p>
                <img src={X} alt="" />
              </p>
              {/* <  */}
            </div>
          </div>
        </div>

        <hr></hr>

        <div className="sb__footer-below">
          <div className="sb__footer-copyright">
            <p>
              @{new Date().getFullYear()} Aristeiaconsult. All right reserved.
            </p>
          </div>
          <div className="sb__footer-below-links">
            <a href="/">
              <div>
                <p>Terms & Conditions</p>
              </div>
            </a>
            <a href="/">
              <div>
                <p>Privacy</p>
              </div>
            </a>
            <a href="/">
              <div>
                <p>Security</p>
              </div>
            </a>
            <a href="/">
              <div>
                <p>Cookies Declaration</p>
              </div>
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Footer;
