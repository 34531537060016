import React from "react";
import "./Slider.css";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Pagination } from "swiper/modules";

import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import "swiper/css/effect-fade";

const data = [
  {
    id: 1,
    name: `Adedamola Oloketuyi`,
    postiton: `Managing Director of AOC Schengen `,
    img: `/images/Adedamola.jpeg`,
    review: `Aristeia has been instrumental in transforming our Zoho experience. The team 
       brings projects to life with precision and expertise. 
      What impresses us most is their rare ability to balance technical skills with business acumen,
       consistently delivering solutions that drive success. With Aristeia, 
       we've achieved outstanding results across multiple regions, including Gambia, Rwanda, Nigeria, and Germany. 
       We highly recommend Aristeia as a trusted partner for maximizing your Zoho potential.`,
  },
  {
    id: 2,
    name: `Moses`,
    img: `/images/avatar.jpeg`,
    postiton: `DOT Asset Finance `,
    review: `Aristeia's professionalism, attention to detail, and commitment to delivering results have made them an invaluable partner for us.
       We highly recommend their services to any organization seeking to optimize their operations and drive growth.`,
  },
  // {
  //   id: 3,
  //   name: `Shola`,
  //   img: `/images/person2.jpg`,
  //   postiton: `Downtown HR Manager`,
  //   review: `Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.`,
  // },
  // {
  //   id: 4,
  //   name: `Rigo Louie`,
  //   img: `/images/person1.jpg`,
  //   postiton: `Loft CEO`,
  //   review: `Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.`,
  // },
  // {
  //   id: 5,
  //   name: `Mia Williams`,
  //   img: `/images/person2.jpg`,
  //   postiton: `Vind Limited CEO`,
  //   review: `Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.`,
  // },
];

const Slider = () => {
  return (
    <div className="section">
      <div>
        <h1 className="title">
          What they say <span className="span-title">about us</span>{" "}
        </h1>
      </div>
      <p className="slider-subtitle">Testimonials from few of our clients </p>

      <Swiper
        slidesPerView={1}
        spaceBetween={30}
        loop={true}
        pagination={{
          clickable: true,
        }}
        navigation={true}
        modules={[Pagination, Navigation]}
        className="mySwiper"
      >
        {data.map((user) => (
          <SwiperSlide key={user.id} className="slide">
            <div className="text">
              "<i>{user.review}</i>"
            </div>

            <p className="position">{user.postiton}</p>
            <p className="title">{user.name}</p>
            <div className="person-img-container">
              <img className="person-img" src={user.img} alt={user.name} />
            </div>
          </SwiperSlide>
        ))}
      </Swiper>
    </div>
  );
};

export default Slider;
