import React from "react";
import "./Services.css";

const Services = () => {
  return (
    <div className="section-services">
      <div className="service-header-text">
        <h1 className="ser-header">
          Our <span className="sub-ser">Services</span>
        </h1>
        <h3 className="ser-pass">We are here to serve anytime anyday</h3>
      </div>

      <div className="cont-service-all">
        <div className="cont-service cont-alt">
          <div className="image-cont">
            <img src="images/CTI.png" className="service-img" alt="custom" />
          </div>
          <div className="cont-text-services">
            <h3 className="header-ser">
              Business Process Customisation,
              <br /> Implementation, Training etc.
              <br /> using Zoho Applications
            </h3>
            <p className="pass-ser">
              Seamlessly integrating Zoho applications to
              <br /> automate and optimize business workflows,
              <br /> ensuring a smooth transition.
              <br /> Equipping teams with the knowledge and
              <br /> skills to unlock the full potential of
              <br /> Zoho applications,fostering a culture of
              <br /> digital excellence and continuous improvement.
            </p>
          </div>
        </div>

        <div className="cont-service cont-alt">
          <div className="cont-text-services">
            <h3 className="header-ser">
              Software Solution Tailored to
              <br /> Your Business
            </h3>
            <p className="pass-ser">
              Optimizing financial outcomes with bespoke
              <br /> software solutions, harmonizing technology
              <br /> and expertise to fuel business success
            </p>
          </div>
          <div className="image-cont">
            <img src="images/SSS.png" className="service-img" alt="tailor" />
          </div>
        </div>

        <div className="cont-service cont-alt">
          <div className="image-cont">
            <img src="images/DMH.png" className="service-img" alt="custom" />
          </div>
          <div className="cont-text-services">
            <h3 className="header-ser">
              Market Analysis and <br /> Historical Data
            </h3>
            <p className="pass-ser">
              Illuminating market opportunities and risks <br />
              through expert analysis of <br />
              historical data and real-time insights.
              <br />
              Transforming historical data into actionable market
              <br /> intelligence,fueling business innovation and success
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Services;
