import React, { useState } from "react";
import { FaBars, FaTimes } from "react-icons/fa";

import "./Header.css";

const Header = () => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  const navlist = [
    { id: 1, title: "Home", url: "/" },
    { id: 2, title: "Contact", url: "#contact" },
    { id: 3, title: "About", url: "#about" },
  ];

  return (
    <div className="bg">
      <div className="header-all sticky">
        <img src="images/logo-W.png" className="logo-b" alt="Company logo" />

        <nav className="navbar">
          <ul className={`navbar-list`}>
            {navlist.map((item) => (
              <li key={item.id}>
                <a className="navbar-list" href={item.url}>
                  {item.title}
                </a>
              </li>
            ))}
          </ul>

          <button className="nav-btn" onClick={toggleMenu}>
            <FaBars className="nav-icon" />
          </button>
        </nav>
      </div>

      {isOpen && (
        <div className="mobile-nav">
          <div className="mobile-nav__header">
            <img src="images/logo-B.png" className="logo-b" alt="" />

            <button className="nav-btn nav-close-btn" onClick={toggleMenu}>
              <FaTimes className="nav-icon" />
            </button>
          </div>

          <nav className="mobile-nav__navbar">
            <ul className={`mobile-nav__nav-list`}>
              {navlist.map((item) => (
                <li key={item.id}>
                  <a
                    className="mobile-nav__nav-route"
                    href={item.url}
                    onClick={toggleMenu}
                  >
                    {item.title}
                  </a>
                </li>
              ))}
            </ul>
          </nav>
        </div>
      )}

      <div className="hero-cont">
        <h1 className="hero-text-1">we are ready to help you</h1>

        <h2 className="hero-text-2">consulting & analysis</h2>

        <p className="hero-pass">
          Our vision is to deliver actionable, data-driven insights that
          <br />
          empower informed decision-making, drive business growth, and
          <br />
          optimize financial performance.
        </p>
        <button className="btn-cont">CONTACT US</button>
      </div>
    </div>
  );
};

export default Header;
