
import './App.css';
import Home from './pages/Home'


function App() {
  return (
   
    <div className="App">
      <Home />
    </div>
   
  );
}

export default App;
