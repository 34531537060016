import React from "react";
import "./Partners.css";

const Partners = () => {
  return (
    <div className="partners">
      <div className="container">
        <h2 className="heading-partners">Our partners</h2>
        <div className="logo-partners">
          <img src="images/Dot.jpeg" className="partner-img" alt="custom" />
          <img src="images/jobber.jpg" className="partner-img" alt="custom" />
          <img src="images/AOC.png" className="partner-img" alt="AOC" />
        </div>
      </div>
    </div>
  );
};

export default Partners;
